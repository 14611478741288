import * as React from 'react';
import { useOfferItems } from 'hooks';

import { Box, Container, Grid, Typography } from '@mui/material';
import MainLayout from 'layouts/MainLayout';
import { graphql } from 'gatsby';
import { OfferDetailsTemplate } from 'templates';
import { Advertisement, CenteredContainer, InlineCard, LongCard } from 'components';
import { Subtitle, Title } from 'components/headers';
import { AnimatedContainer } from 'components/containers';

const OfferDetails = ({ data: { offerDetails } }: any) => {
  const items = useOfferItems();
  return (
    <MainLayout
      pageTitle={offerDetails.pageTitle}
      metaTitle={offerDetails.metaTitle}
      metaDescription={offerDetails.metaDescription}
      offerItems={items}
      darkNavbar
    >
      <AnimatedContainer delay={100}>
        <OfferDetailsTemplate data={offerDetails}>
          <Container maxWidth="lg" sx={{ pt: { xs: 4, md: 7 }, pb: { xs: 2, md: 7 } }}>
            <CenteredContainer>
              <Title>{offerDetails.title}</Title>
              <Subtitle>
                <Box component="span" dangerouslySetInnerHTML={{ __html: offerDetails.subtitle }} />
              </Subtitle>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: offerDetails.text }}
                sx={{ whiteSpace: 'pre-line', mb: 7, mt: -5, textAlign: 'center' }}
              />
              <Grid container spacing={{ xs: 2, md: 5 }} columns={{ xs: 10, md: 12 }}>
                {offerDetails.shortItems.map((el: any, i: number) => (
                  <Grid item xs key={el.key}>
                    <AnimatedContainer sal="zoom-in" delay={i * 100}>
                      <InlineCard
                        icon={el.icon.src.svg.content}
                        color={offerDetails.color}
                        title={el.title}
                        text={el.text}
                      />
                    </AnimatedContainer>
                  </Grid>
                ))}
              </Grid>

              <Box sx={{ mt: 10, width: '100%' }}>
                {offerDetails.items.map((el: { title: string; text: string }, i: number) => (
                  <AnimatedContainer key={el.title} sal="zoom-in" delay={i * 100}>
                    <LongCard title={el.title} text={el.text} color={offerDetails.color} />
                  </AnimatedContainer>
                ))}
              </Box>
            </CenteredContainer>
          </Container>
        </OfferDetailsTemplate>
        <AnimatedContainer>
          <Advertisement text={offerDetails.banner.text} color="primary.main" />
        </AnimatedContainer>
      </AnimatedContainer>
    </MainLayout>
  );
};

export const query = graphql`
  query ($locale: String, $name: String) {
    offerDetails(name: { eq: $name }, locale: { eq: $locale }) {
      metaTitle
      metaDescription
      title
      pageTitle
      pageSubtitle
      pageIcon {
        svg {
          content
        }
      }
      subtitle
      color
      shortItems {
        key
        title
        icon {
          src {
            svg {
              content
            }
          }
        }
      }
      items {
        title
        text
      }
      banner {
        text
        contactButton
      }
    }
  }
`;

export default OfferDetails;
