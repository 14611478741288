import React, { memo, ReactNode } from 'react';

import { Container, Typography } from '@mui/material';

interface TitleProps {
  children: ReactNode;
  align?: 'center' | 'left' | 'right';
  light?: boolean;
}

const Title = memo(({ children, align = 'center', light = false }: TitleProps) => {
  return (
    <Container maxWidth="lg" sx={{ p: { md: '0 !important' } }}>
      <Typography color={light ? 'common.white' : 'common.dark'} variant="h2" sx={{ pb: 4 }} align={align}>
        {children}
      </Typography>
    </Container>
  );
});

export { Title };
