import React, { memo, ReactNode } from 'react';

import { Container, Typography } from '@mui/material';

interface SubtitleProps {
  children: ReactNode;
  maxWidth?: 'md' | 'lg';
  align?: 'center' | 'left' | 'right';
  light?: boolean;
}

const Subtitle = memo(({ children, maxWidth = 'md', align = 'center', light = false }: SubtitleProps) => {
  return (
    <Container maxWidth={maxWidth} sx={{ mb: 4, whiteSpace: 'pre-line', p: { md: '0 !important' } }}>
      <Typography
        variant="h4"
        component="p"
        color={light ? 'common.lightGrey' : 'common.lightDark'}
        sx={{ pb: { xs: 2, md: 3 } }}
        align={align}
      >
        {children}
      </Typography>
    </Container>
  );
});

export { Subtitle };
