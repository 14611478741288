import React, { memo, ReactNode } from 'react';

import { Container, Typography } from '@mui/material';

interface SumtitleProps {
  children: ReactNode;
  maxWidth?: 'md' | 'lg';
  align?: 'center' | 'left' | 'right';
}

const Sumtitle = memo(({ children, maxWidth = 'md', align = 'center' }: SumtitleProps) => {
  return (
    <Container maxWidth={maxWidth} sx={{ mb: 1, whiteSpace: 'pre-line', p: { md: '0 !important' } }}>
      <Typography variant="h6" color="primary.main" sx={{ textTransform: 'uppercase' }} align={align}>
        {children}
      </Typography>
    </Container>
  );
});

export { Sumtitle };
