import React, { memo } from 'react';

import { ReferenceCard } from 'components';
import { Title, Sumtitle, Subtitle } from 'components/headers';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';
import { AnimatedContainer } from 'components/containers';

const StyledDotBox = styled(Box)(({ theme }) => ({
  width: '15px',
  height: '15px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  transition: 'all 0.3s ease 0s',
  '&:hover': {
    backgroundColor: theme.palette.primary.light
  }
}));

type ReferencesTemplateProps = {
  data: {
    sum: string;
    title: string;
    subtitle: string;
    items: {
      type: string;
      grid?: { xs: number; md: number };
      data: {
        key: string;
        componyLogo?: string;
        authorName: string;
        authorDetails: string;
        challenge: string;
        solution: string;
        result: string;
        authorImg: { src: { publicURL: string } };
      }[];
    };
  };
};

const ReferencesTemplate = memo(({ data: { sum, title, subtitle, items } }: ReferencesTemplateProps) => {
  const theme = useTheme();
  const matchesDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const carouselSettings = {
    dots: true,
    infinite: true,
    dotsClass: 'slick-dots slick-thumb',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: (dots: any) => {
      return (
        <Box sx={boxStyle}>
          <ul style={{ margin: 0 }}> {dots} </ul>
        </Box>
      );
    },
    customPaging: () => <StyledDotBox />
  };
  return (
    <AnimatedContainer delay={100} noAnimation={matchesDownMD}>
      <Box sx={{ pb: items.data.length > 1 ? 6 : 0 }}>
        <Sumtitle>{sum}</Sumtitle>
        <Title>{title}</Title>
        <Subtitle>
          <Box component="span" dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Subtitle>
        <Box>
          <Slider {...carouselSettings}>
            {items.data.map(el => (
              <ReferenceCard
                key={el.key}
                challenge={el.challenge}
                solution={el.solution}
                result={el.result}
                authorName={el.authorName}
                authorDetails={el.authorDetails}
                authorImg={el.authorImg.src.publicURL}
              />
            ))}
          </Slider>
        </Box>
      </Box>
    </AnimatedContainer>
  );
});

export const boxStyle = {
  bottom: '-80px !important',
  '& li:not(.slick-active):hover': {
    '& div': {
      transform: 'scale(1.1)'
    }
  },
  '& .slick-active > div': {
    backgroundColor: 'primary.dark'
  }
};

export { ReferencesTemplate };
