import React, { memo, ReactNode } from 'react';

import { ContainerWithImg } from 'components';
import { Box, Container, Typography } from '@mui/material';
import bgrDark from 'images/bgr/bgr-dark-vert.inline.png';

type OfferDetailsTemplateProps = {
  data: {
    pageTitle: string;
    pageSubtitle: string;
    pageIcon: { svg: { content: string } };
    title: string;
    subtitle: string;
    items: {
      title: string;
      text: string;
    }[];
  };
  children: ReactNode;
};

const OfferDetailsTemplate = memo(
  ({ data: { pageTitle, pageSubtitle, pageIcon }, children }: OfferDetailsTemplateProps) => {
    return (
      <div>
        <Box
          sx={{
            background: `url(${bgrDark}) no-repeat center center / cover`,
            backgroundColor: 'common.dark',
            pb: 8
          }}
        >
          <Container maxWidth="lg" sx={{ pt: { xs: 4, md: 8 }, pb: { xs: 2, md: 10 }, position: 'relative' }}>
            <ContainerWithImg icon={pageIcon.svg.content}>
              <>
                <Typography
                  color="common.lightGrey"
                  variant="h1"
                  sx={{ whiteSpace: 'pre-line' }}
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  {pageTitle}
                </Typography>
                <Typography
                  color="common.lightGrey"
                  variant="h3"
                  mt={3}
                  sx={{ whiteSpace: 'pre-line' }}
                  textAlign={{ xs: 'center', md: 'left' }}
                  dangerouslySetInnerHTML={{ __html: pageSubtitle }}
                />
              </>
            </ContainerWithImg>
          </Container>
        </Box>
        <Box>{children}</Box>
      </div>
    );
  }
);

export { OfferDetailsTemplate };
