import React from 'react';

import { ColoredCard, InlineCard, LongCard, MainCard, SimpleCard } from 'components';
import Grid from '@mui/material/Grid';
import { SimpleColor } from 'themes/material-ui';
import { Title, Subtitle, Sumtitle } from 'components/headers';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AnimatedContainer } from 'components/containers';

type GenericTemplateProps = {
  data: {
    sum: string;
    title: string;
    subtitle: string;
    titleAlign?: 'right' | 'left' | 'center';
    items: {
      type: string;
      grid?: { xs: number; md: number };
      data: {
        key: string;
        title: string;
        text: string;
        color: keyof SimpleColor;
        icon: { src: { svg: { content: string } } };
      }[];
    };
    img?: { src: { publicURL: string } };
    darkBgr?: boolean;
  };
};

const GenericTemplate = ({
  data: { sum, title, subtitle, titleAlign = 'center', items, img, darkBgr }
}: GenericTemplateProps) => {
  const theme = useTheme();
  const matchesDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const headerAlign = !matchesDownMD && titleAlign !== 'center' ? 'left' : 'center';
  const gridDirection = titleAlign === 'center' ? 'column' : titleAlign === 'right' ? 'row-reverse' : 'row';

  return (
    <AnimatedContainer delay={100} noAnimation={matchesDownMD}>
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent={titleAlign === 'center' ? 'center' : 'space-between'}
        flexDirection={{
          xs: 'column',
          md: gridDirection
        }}
        rowSpacing={{ xs: 0, md: 2 }}
        columnSpacing={{ xs: 0, md: 16 }}
        columns={{ xs: 10, md: 12 }}
      >
        {title && (
          <Grid item xs={12} md={6}>
            <Sumtitle align={headerAlign}>{sum}</Sumtitle>
            <Title light={!!darkBgr} align={headerAlign}>
              {title}
            </Title>
            <Subtitle light={!!darkBgr} align={headerAlign}>
              <Box component="span" dangerouslySetInnerHTML={{ __html: subtitle }} />
            </Subtitle>
          </Grid>
        )}
        <Grid item xs={title ? 6 : 12} md={title ? 6 : 12}>
          {items && (
            <>
              {items.type === 'inline-cards' && (
                <Grid container columnSpacing={6} rowSpacing={6}>
                  {items?.data.map((el, i) => (
                    <Grid key={el.key} item {...items.grid}>
                      <AnimatedContainer sal="zoom-in" delay={i * 100}>
                        <InlineCard icon={el.icon.src.svg.content} color={el.color} title={el.title} text={el.text} />
                      </AnimatedContainer>
                    </Grid>
                  ))}
                </Grid>
              )}
              {items.type === 'simple-cards' && (
                <Grid container spacing={{ xs: 2, md: 2, lg: 3, xl: 4 }} sx={{ px: { lg: 3 } }}>
                  {items.data.map((el, i) => (
                    <Grid
                      container
                      item
                      {...items.grid}
                      my={2}
                      key={i}
                      direction="column"
                      alignItems="stretch"
                      data-sal="zoom-in"
                      data-sal-delay={i * 100}
                      data-sal-easing="ease"
                    >
                      <SimpleCard icon={el.icon.src.svg.content} color={el.color} title={el.title} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {items.type === 'detailed-cards' && (
                <Grid container item spacing={{ xs: 4, sm: 2, md: 4 }} justifyContent="center">
                  {items.data.map((el, i) => (
                    <Grid
                      container
                      item
                      {...items.grid}
                      key={el.key}
                      justifyContent="center"
                      data-sal="zoom-in"
                      data-sal-delay={i * 400}
                      data-sal-easing="ease"
                    >
                      <MainCard
                        icon={el.icon.src.svg.content}
                        color={el.color}
                        title={el.title}
                        text={el.text}
                        link={`offer-details/${el.key}`}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {items.type === 'colored-cards' && (
                <Grid container item spacing={{ xs: 4, sm: 2, md: 4 }} justifyContent="center">
                  {items.data.map((el, i) => (
                    <Grid
                      container
                      item
                      {...items.grid}
                      key={el.key}
                      direction="column"
                      alignItems="stretch"
                      data-sal="zoom-in"
                      data-sal-delay={i * 100}
                      data-sal-easing="ease"
                    >
                      <ColoredCard color={el.color} title={el.title} text={el.text} icon={el.icon.src.svg.content} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {items.type === 'long-cards' && (
                <Box sx={{ width: '100%' }}>
                  {items.data.map((el, i) => (
                    <AnimatedContainer key={el.title} sal="zoom-in" delay={i * 200}>
                      <LongCard title={el.title} text={el.text} color={el.color} darkBgr={!!darkBgr} />
                    </AnimatedContainer>
                  ))}
                </Box>
              )}
            </>
          )}
          {img && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                '& img': {
                  width: { xs: '70%', md: '100%' },
                  height: 'auto'
                }
              }}
            >
              <img src={img.src.publicURL} alt="image" width="620" />
            </Box>
          )}
        </Grid>
      </Grid>
    </AnimatedContainer>
  );
};

export { GenericTemplate };
